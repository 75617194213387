import { registerSW } from "virtual:pwa-register"

import { createLogger } from "./logger"

const logger = createLogger("sw")

export const NEW_VERSION_AVAILABLE_EVENT = "newVersionAvailable"

export function initPWA() {
  logger.log("Initializing PWA...")

  const updateSW = registerSW({
    onNeedRefresh() {
      logger.log("New version available, prompting user to refresh")

      alert("New version available. The app will update now.")

      updateSW() // Trigger the update

      logger.log("Updating to new version...")

      window.dispatchEvent(new CustomEvent(NEW_VERSION_AVAILABLE_EVENT))
    },
    onOfflineReady() {
      logger.log("App ready to work offline")
    },
    onRegistered(registration) {
      registration?.update()
      logger.log("Service Worker registered successfully", registration)
    },
    onRegisterError(error) {
      logger.error("Service Worker registration failed:", error)
    },
  })

  return updateSW
}

export function isAppInstalled() {
  const isIosPWA = (window.navigator as Navigator & { standalone?: boolean }).standalone === true
  const isStandalone = window.matchMedia("(display-mode: standalone)").matches
  return isIosPWA || isStandalone
}
