import { z } from "zod"

export enum TransactionStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  REVERTED = "REVERTED",
}

export enum TransactionType {
  ADD_INCENTIVE = "ADD_INCENTIVE",
  ADD_LIQUIDITY = "ADD_LIQUIDITY",
  APPROVE = "APPROVE",
  CLAIM = "CLAIM", // Legacy
  CLAIM_POOL = "CLAIM_POOL",
  CLAIM_REWARD = "CLAIM_REWARD",
  CLAIM_SYNTHETIC = "CLAIM_SYNTHETIC",
  CLOSE_EPOCH = "CLOSE_EPOCH",
  DEPOSIT = "DEPOSIT",
  DEPOSIT_STAKE = "DEPOSIT_STAKE",
  MERGE_NFT = "MERGE_NFT",
  MIGRATE = "MIGRATE",
  REBASE = "REBASE",
  REBUILD_STAKE = "REBUILD_STAKE",
  SPLIT_NFT = "SPLIT_NFT",
  STAKE_LIQUIDITY = "STAKE_LIQUIDITY",
  SWAP = "SWAP",
  TRANSFER = "TRANSFER",
  UNSTAKE_LIQUIDITY = "UNSTAKE_LIQUIDITY",
  VOTE = "VOTE",
  WITHDRAW = "WITHDRAW",
  WITHDRAW_LIQUIDITY = "WITHDRAW_LIQUIDITY",
  WITHDRAW_STAKE = "WITHDRAW_STAKE",
  INCREASE_STAKE = "INCREASE_STAKE",
}

const TransactionValidations = z.object({
  hash: z.string().optional(),
  userOpHash: z.string().optional(),
  account: z.string(),
  status: z.nativeEnum(TransactionStatus).optional(),
  date: z.date().optional(),
  type: z.nativeEnum(TransactionType),
})

export type TransactionSerialized = z.infer<typeof TransactionValidations>

export class Transaction {
  constructor(
    private readonly _hash: string | undefined,
    private readonly _userOpHash: string | undefined,
    private readonly _account: string,
    private readonly _status: TransactionStatus,
    private readonly _type: TransactionType,
    private readonly _date?: Date,
  ) {}

  static create({
    hash,
    userOpHash,
    account,
    type,
    status = TransactionStatus.PENDING,
    date = new Date(),
  }: z.infer<typeof TransactionValidations>) {
    if (!hash && !userOpHash) throw new Error("hash or userOpHash is required")
    TransactionValidations.parse({ hash, userOpHash, account, status, type, date })
    return new Transaction(hash, userOpHash, account, status, type, date)
  }

  static STATUS = TransactionStatus
  static TYPES = TransactionType

  get hash() { return this._hash } // prettier-ignore
  get userOpHash() { return this._userOpHash } // prettier-ignore
  get account() { return this._account } // prettier-ignore
  get status () { return this._status } // prettier-ignore
  get date () { return this._date } // prettier-ignore
  get type () { return this._type } // prettier-ignore
  get explorerUrl() {
    const client = window.publicClient
    if (!this.hash) return `https://jiffyscan.xyz/userOpHash/${this.userOpHash}?network=${client.chain?.id}`
    const explorerUrl = client.chain?.blockExplorers?.default.url
    if (!explorerUrl) return undefined
    return `${explorerUrl}/tx/${this.hash}`
  }

  serialize() {
    return {
      hash: this.hash,
      userOpHash: this.userOpHash,
      account: this.account,
      status: this.status,
      explorerUrl: this.explorerUrl,
      date: this.date,
      type: this.type,
    }
  }
}
