import type { Base } from "./_config/index.js"
import { Config } from "./_config/index.js"
import { Transaction } from "./ethereum/Models/Transaction.js"
import { WalletState } from "./ethereum/Models/WalletState.js"
import { type ConnectEthereumUseCaseInput } from "./ethereum/UseCases/ConnectEthereumUseCase.js"
import { type WaitForTransactionEthereumUseCaseInput } from "./ethereum/UseCases/WaitForTransactionEthereumUseCase.js"
import { type RegisterEthereumUseCaseInput } from "./ethereum/UseCases/RegisterEthereumUseCase.js"
import { type LoginEthereumUseCaseInput } from "./ethereum/UseCases/LoginEthereumUseCase.js"
import { type AddTokenToWalletEthereumUseCaseInput } from "./ethereum/UseCases/AddTokenToWalletEthereumUseCase.js"
import { type TransferTokensEthereumUseCaseInput } from "./ethereum/UseCases/TransferTokensEthereumUseCase.js"
import { ProjectConfig } from "./project/Models/ProjectConfig.js"
import { ProjectStats } from "./project/Models/ProjectStats.js"
import { TokenPriceList } from "./token/Models/PriceList.js"
import { UserBalances } from "./user/Models/UserBalances.js"
import { type ClaimAllocationRewardUserUseCaseInput } from "./user/UseCases/ClaimAllocationRewardUserUseCase.js"
import { type ClaimMerkleRewardUserUseCaseInput } from "./user/UseCases/ClaimMerkleRewardUserUseCase.js"
import { type GetAirdropRewardsUserUseCaseOutput } from "./user/UseCases/GetAirdropRewardsUserUseCase.js"
import { type ApprovePoolUseCaseInput } from "./pool/UseCases/ApprovePoolUseCase.js"
import { type ApproveStakePoolUseCaseInput } from "./pool/UseCases/ApproveStakePoolUseCase.js"
import { type ClaimRewardsPoolUseCaseInput } from "./pool/UseCases/ClaimRewardsPoolUseCase.js"
import { type AddLiquidityPoolUseCaseInput } from "./pool/UseCases/AddLiquidityPoolUseCase.js"
import { type DepositBundlePoolUseCaseInput } from "./pool/UseCases/DepositBundlePoolUseCase.js"
import { type DepositStakePoolUseCaseInput } from "./pool/UseCases/DepositStakePoolUseCase.js"
import { type GetRouterAllowancesPoolUseCaseInput } from "./pool/UseCases/GetRouterAllowancesPoolUseCase.js"
import { type GetDepositAmountsPoolUseCaseInput } from "./pool/UseCases/GetDepositAmountsPoolUseCase.js"
import { type GetPoolStatsUseCaseInput } from "./pool/UseCases/GetPoolStatsUseCase.js"
import { type GetStakeAllowancePoolUseCaseInput } from "./pool/UseCases/GetStakeAllowancePoolUseCase.js"
import { type GetUserPoolStatsUseCaseInput } from "./pool/UseCases/GetUserPoolStatsUseCase.js"
import { type GetSwapPathPoolUseCaseInput } from "./pool/UseCases/GetSwapPathPoolUseCase.js"
import { type SwapTokensPoolUseCaseInput } from "./pool/UseCases/SwapTokensPoolUseCase.js"
import { type RemoveLiquidityPoolUseCaseInput } from "./pool/UseCases/RemoveLiquidityPoolUseCase.js"
import { type WithdrawStakePoolUseCaseInput } from "./pool/UseCases/WithdrawStakePoolUseCase.js"
import { type GetWithdrawAmountsPoolUseCaseInput } from "./pool/UseCases/GetWithdrawAmountsPoolUseCase.js"
import { type GetRemoveLiquidityAmountsPoolUseCaseInput } from "./pool/UseCases/GetRemoveLiquidityAmountsPoolUseCase.js"
import { type WithdrawBundlePoolUseCaseInput } from "./pool/UseCases/WithdrawBundlePoolUseCase.js"
import { TokenAmount } from "./token/Models/TokenAmount.js"
import { PoolStats } from "./pool/Models/PoolStats.js"
import { PoolStatsList } from "./pool/Models/PoolStatsList.js"
import { UserPoolStatsList } from "./pool/Models/UserPoolStatsList.js"
import { SwapPath } from "./pool/Models/SwapPath.js"
import {
  DepositStakeSyntheticUseCaseInput,
  DepositStakeSyntheticUseCaseOutput,
} from "./synthetic/UseCases/DepositStakeSyntheticUseCase.js"
import {
  GetDepositAmountSyntheticUseCaseInput,
  GetDepositAmountSyntheticUseCaseOutput,
} from "./synthetic/UseCases/GetDepositAmountSyntheticUseCase.js"
import { UserPoolStats } from "./pool/Models/UserPoolStats.js"
import {
  GetUserSyntheticDepositsSyntheticUseCaseInput,
  GetUserSyntheticDepositsSyntheticUseCaseOutput,
} from "./synthetic/UseCases/GetUserSyntheticDepositsSyntheticUseCase.js"
import {
  UpdateStakeLockTimeSyntheticUseCaseInput,
  UpdateStakeLockTimeSyntheticUseCaseOutput,
} from "./synthetic/UseCases/UpdateStakeLockTimeSyntheticUseCase.js"
import {
  UpdateStakeAmountSyntheticUseCaseInput,
  UpdateStakeAmountSyntheticUseCaseOutput,
} from "./synthetic/UseCases/UpdateStakeAmountSyntheticUseCase.js"
import { type ClaimFUSDTRewardUserUseCaseInput } from "./user/UseCases/ClaimFUSDTRewardUserUseCase.js"
import {
  GetByIDUserSyntheticDepositSyntheticUseCaseInput,
  GetByIDUserSyntheticDepositSyntheticUseCaseOutput,
} from "./synthetic/UseCases/GetByIDUserSyntheticDepositSyntheticUseCase.js"
import {
  WithdrawStakeSyntheticUseCaseInput,
  WithdrawStakeSyntheticUseCaseOutput,
} from "./synthetic/UseCases/WithdrawStakeSyntheticUseCase.js"
import { EpochPoolStatsList } from "./synthetic/Models/EpochPoolStatsList.js"
import { type GetCompletedEpochPoolStatsListSyntheticUseCaseInput } from "./synthetic/UseCases/GetCompletedEpochPoolStatsListSyntheticUseCase.js"
import { type GetCurrentUserEpochPoolVotesListSyntheticUseCaseInput } from "./synthetic/UseCases/GetCurrentUserEpochPoolVotesListSyntheticUseCase.js"
import { UserEpochPoolVotesList } from "./synthetic/Models/UserEpochPoolVotesList.js"
import { type GetCompletedUserEpochPoolVotesListSyntheticUseCaseInput } from "./synthetic/UseCases/GetCompletedUserEpochPoolVotesListSyntheticUseCase.js"
import { type GetUserEpochRewardsSyntheticUseCaseInput } from "./synthetic/UseCases/GetUserEpochRewardsSyntheticUseCase.js"
import { UserEpochRewards } from "./synthetic/Models/UserEpochRewards.js"
import { EpochInfo } from "./synthetic/Models/EpochInfo.js"
import {
  type VotePoolsSyntheticUseCaseInput,
  type VotePoolsSyntheticUseCaseOutput,
} from "./synthetic/UseCases/VotePoolsSyntheticUseCase.js"
import {
  type ClaimRewardsSyntheticUseCaseInput,
  type ClaimRewardsSyntheticUseCaseOutput,
} from "./synthetic/UseCases/ClaimRewardsSyntheticUseCase.js"
import {
  GetSyntheticStatsListSyntheticUseCaseInput,
  GetSyntheticStatsListSyntheticUseCaseOutput,
} from "./synthetic/UseCases/GetSyntheticStatsListSyntheticUseCase.js"
import {
  RebuildStakeSyntheticUseCaseInput,
  RebuildStakeSyntheticUseCaseOutput,
} from "./synthetic/UseCases/RebuildStakeSyntheticUseCase.js"
import { GetHistoricalStatsSyntheticUseCaseOutput } from "./synthetic/UseCases/GetHistoricalStatsSyntheticUseCase.js"
import {
  RebaseSyntheticUseCaseInput,
  RebaseSyntheticUseCaseOutput,
} from "./synthetic/UseCases/RebaseSyntheticUseCase.js"
import { MigrationInfo } from "./migration/Models/MigrationInfo.js"
import {
  WithdrawYieldMigrationUseCaseInput,
  WithdrawYieldMigrationUseCaseOutput,
} from "./migration/UseCases/WithdrawYieldMigrationUseCase.js"
import {
  ApproveTokenMigrationUseCaseInput,
  ApproveTokenMigrationUseCaseOutput,
} from "./migration/UseCases/ApproveTokenMigrationUseCase.js"
import {
  MigrateMigrationUseCaseInput,
  MigrateMigrationUseCaseOutput,
} from "./migration/UseCases/MigrateMigrationUseCase.js"

import { GetGlobalInfoSyntheticUseCaseOutput } from "./synthetic/UseCases/GetGlobalInfoSyntheticUseCase.js"
import { GetTransactionsEthereumUseCaseOutput } from "./ethereum/UseCases/GetTransactionsEthereumUseCase.js"
import { CloseEpochAdminUseCaseOutput } from "./admin/UseCases/CloseEpochAdminUseCase.js"
import {
  AddPoolIncentiveAdminUseCaseInput,
  AddPoolIncentiveAdminUseCaseOutput,
} from "./admin/UseCases/AddPoolIncentiveAdminUseCase.js"
import {
  GetPoolIncentiveAllowanceAdminUseCaseInput,
  GetPoolIncentiveAllowanceAdminUseCaseOutput,
} from "./admin/UseCases/GetPoolIncentiveAllowanceAdminUseCase.js"
import {
  ApprovePoolIncentiveAdminUseCaseInput,
  ApprovePoolIncentiveAdminUseCaseOutput,
} from "./admin/UseCases/ApprovePoolIncentiveAdminUseCase.js"
import {
  TransferExternalTokensEthereumUseCaseInput,
  TransferExternalTokensEthereumUseCaseOutput,
} from "./ethereum/UseCases/TransferExternalTokensEthereumUseCase.js"
import { GetHistoricalEpochRewardsSyntheticUseCaseOutput } from "./synthetic/UseCases/GetHistoricalEpochRewardsSyntheticUseCase.js"
import { GetHistoricalLiquidityRewardsPoolUseCaseOutput } from "./pool/UseCases/GetHistoricalLiquidityRewardsPoolUseCase.js"
import { SplitSyntheticUseCaseInput, SplitSyntheticUseCaseOutput } from "./synthetic/UseCases/SplitSyntheticUseCase.js"
import { MergeSyntheticUseCaseInput, MergeSyntheticUseCaseOutput } from "./synthetic/UseCases/MergeSyntheticUseCase.js"

export class Domain {
  #config: Config

  static create(config: Base) {
    return new Domain(config)
  }

  constructor(config: Base) {
    this.#config = Config.create(config)
  }

  get config() {
    return this.#config
  }

  /** Project */
  get GetConfigProjectUseCase() { return this.#getter<void, ProjectConfig>(async () => import("./project/UseCases/GetConfigProjectUseCase.js"), "GetConfigProjectUseCase") } // prettier-ignore
  get GetStatsProjectUseCase () { return this.#getter<void, ProjectStats>(async () => import("./project/UseCases/GetStatsProjectUseCase.js"), "GetStatsProjectUseCase") } // prettier-ignore

  /** Ethereum */
  get InitPublicClientEthereumUseCase () { return this.#getter<void, void>(async () => import("./ethereum/UseCases/InitPublicClientEthereumUseCase.js"), "InitPublicClientEthereumUseCase") } // prettier-ignore
  get ConnectEthereumUseCase() { return this.#getter<ConnectEthereumUseCaseInput, WalletState>(async () => import("./ethereum/UseCases/ConnectEthereumUseCase.js"), "ConnectEthereumUseCase") } // prettier-ignore
  get DisconnectEthereumUseCase() { return this.#getter<void, WalletState>(async () => import("./ethereum/UseCases/DisconnectEthereumUseCase.js"), "DisconnectEthereumUseCase") } // prettier-ignore
  get GetWalletStateEthereumUseCase() { return this.#getter<void, WalletState>(async () => import("./ethereum/UseCases/GetWalletStateEthereumUseCase.js"), "GetWalletStateEthereumUseCase") } // prettier-ignore
  get SwitchChainEthereumUseCase() { return this.#getter<void, WalletState>(async () => import("./ethereum/UseCases/SwitchChainEthereumUseCase.js"), "SwitchChainEthereumUseCase") } // prettier-ignore
  get ReconnectEthereumUseCase() { return this.#getter<void, WalletState>(async () => import("./ethereum/UseCases/ReconnectEthereumUseCase.js"), "ReconnectEthereumUseCase") } // prettier-ignore
  get GetTransactionsEthereumUseCase() { return this.#getter<void, GetTransactionsEthereumUseCaseOutput>(async () => import("./ethereum/UseCases/GetTransactionsEthereumUseCase.js"), "GetTransactionsEthereumUseCase") } // prettier-ignore
  get WaitForTransactionEthereumUseCase() { return this.#getter<WaitForTransactionEthereumUseCaseInput, Transaction>(async () => import("./ethereum/UseCases/WaitForTransactionEthereumUseCase.js"), "WaitForTransactionEthereumUseCase") } // prettier-ignore
  get RegisterEthereumUseCase() { return this.#getter<RegisterEthereumUseCaseInput, WalletState>(async () => import("./ethereum/UseCases/RegisterEthereumUseCase.js"), "RegisterEthereumUseCase") } // prettier-ignore
  get LoginEthereumUseCase() { return this.#getter<LoginEthereumUseCaseInput, WalletState>(async () => import("./ethereum/UseCases/LoginEthereumUseCase.js"), "LoginEthereumUseCase") } // prettier-ignore
  get AddTokenToWalletEthereumUseCase() { return this.#getter<AddTokenToWalletEthereumUseCaseInput, void>(async () => import("./ethereum/UseCases/AddTokenToWalletEthereumUseCase.js"), "AddTokenToWalletEthereumUseCase") } // prettier-ignore
  get TransferTokensEthereumUseCase () { return this.#getter<TransferTokensEthereumUseCaseInput, Transaction>(async () => import("./ethereum/UseCases/TransferTokensEthereumUseCase.js"), "TransferTokensEthereumUseCase") } // prettier-ignore
  get TransferExternalTokensEthereumUseCase(){ return this.#getter <TransferExternalTokensEthereumUseCaseInput, TransferExternalTokensEthereumUseCaseOutput>(async () => import("./ethereum/UseCases/TransferExternalTokensEthereumUseCase.js"), "TransferExternalTokensEthereumUseCase") } // prettier-ignore

  /** Token */
  get GetPriceListTokenUseCase() { return this.#getter<void, TokenPriceList>(async () => import("./token/UseCases/GetPriceListTokenUseCase.js"), "GetPriceListTokenUseCase") } // prettier-ignore

  /** Pool */
  get ApprovePoolUseCase() { return this.#getter<ApprovePoolUseCaseInput, Transaction>(async () => import("./pool/UseCases/ApprovePoolUseCase.js"), "ApprovePoolUseCase") } // prettier-ignore
  get ApproveStakePoolUseCase() { return this.#getter<ApproveStakePoolUseCaseInput, Transaction>(async () => import("./pool/UseCases/ApproveStakePoolUseCase.js"), "ApproveStakePoolUseCase") } // prettier-ignore
  get ClaimRewardsPoolUseCase() { return this.#getter<ClaimRewardsPoolUseCaseInput, Transaction>(async () => import("./pool/UseCases/ClaimRewardsPoolUseCase.js"), "ClaimRewardsPoolUseCase") } // prettier-ignore
  get AddLiquidityPoolUseCase() { return this.#getter<AddLiquidityPoolUseCaseInput, Transaction>(async () => import("./pool/UseCases/AddLiquidityPoolUseCase.js"), "AddLiquidityPoolUseCase") } // prettier-ignore
  get DepositBundlePoolUseCase() { return this.#getter<DepositBundlePoolUseCaseInput, Transaction>(async () => import("./pool/UseCases/DepositBundlePoolUseCase.js"), "DepositBundlePoolUseCase") } // prettier-ignore
  get DepositStakePoolUseCase() { return this.#getter<DepositStakePoolUseCaseInput, Transaction>(async () => import("./pool/UseCases/DepositStakePoolUseCase.js"), "DepositStakePoolUseCase") } // prettier-ignore
  get GetRouterAllowancesPoolUseCase() { return this.#getter<GetRouterAllowancesPoolUseCaseInput, TokenAmount[]>(async () => import("./pool/UseCases/GetRouterAllowancesPoolUseCase.js"), "GetRouterAllowancesPoolUseCase") } // prettier-ignore
  get GetDepositAmountsPoolUseCase() { return this.#getter<GetDepositAmountsPoolUseCaseInput, TokenAmount[]>(async () => import("./pool/UseCases/GetDepositAmountsPoolUseCase.js"), "GetDepositAmountsPoolUseCase") } // prettier-ignore
  get GetPoolStatsUseCase() { return this.#getter<GetPoolStatsUseCaseInput, PoolStats>(async () => import("./pool/UseCases/GetPoolStatsUseCase.js"), "GetPoolStatsUseCase") } // prettier-ignore
  get GetPoolStatsListUseCase() { return this.#getter<void, PoolStatsList>(async () => import("./pool/UseCases/GetPoolStatsListUseCase.js"), "GetPoolStatsListUseCase") } // prettier-ignore
  get GetStakeAllowancePoolUseCase() { return this.#getter<GetStakeAllowancePoolUseCaseInput, TokenAmount>(async () => import("./pool/UseCases/GetStakeAllowancePoolUseCase.js"), "GetStakeAllowancePoolUseCase") } // prettier-ignore
  get GetUserPoolStatsUseCase() { return this.#getter<GetUserPoolStatsUseCaseInput, UserPoolStats>(async () => import("./pool/UseCases/GetUserPoolStatsUseCase.js"), "GetUserPoolStatsUseCase") } // prettier-ignore
  get GetUserPoolStatsListUseCase() { return this.#getter<void, UserPoolStatsList>(async () => import("./pool/UseCases/GetUserPoolStatsListUseCase.js"), "GetUserPoolStatsListUseCase") } // prettier-ignore
  get GetSwapPathPoolUseCase() { return this.#getter<GetSwapPathPoolUseCaseInput, SwapPath>(async () => import("./pool/UseCases/GetSwapPathPoolUseCase.js"), "GetSwapPathPoolUseCase") } // prettier-ignore
  get GetWithdrawAmountsPoolUseCase() { return this.#getter<GetWithdrawAmountsPoolUseCaseInput, TokenAmount[]>(async () => import("./pool/UseCases/GetWithdrawAmountsPoolUseCase.js"), "GetWithdrawAmountsPoolUseCase") } // prettier-ignore
  get SwapTokensPoolUseCase() { return this.#getter<SwapTokensPoolUseCaseInput, Transaction>(async () => import("./pool/UseCases/SwapTokensPoolUseCase.js"), "SwapTokensPoolUseCase") } // prettier-ignore
  get RemoveLiquidityPoolUseCase() { return this.#getter<RemoveLiquidityPoolUseCaseInput, Transaction>(async () => import("./pool/UseCases/RemoveLiquidityPoolUseCase.js"), "RemoveLiquidityPoolUseCase") } // prettier-ignore
  get WithdrawStakePoolUseCase() { return this.#getter<WithdrawStakePoolUseCaseInput, Transaction>(async () => import("./pool/UseCases/WithdrawStakePoolUseCase.js"), "WithdrawStakePoolUseCase") } // prettier-ignore
  get ClaimAllRewardsPoolUseCase() { return this.#getter<void, Transaction>(async () => import("./pool/UseCases/ClaimAllRewardsPoolUseCase.js"), "ClaimAllRewardsPoolUseCase") } // prettier-ignore
  get GetRemoveLiquidityAmountsPoolUseCase() { return this.#getter<GetRemoveLiquidityAmountsPoolUseCaseInput, TokenAmount[]>(async () => import("./pool/UseCases/GetRemoveLiquidityAmountsPoolUseCase.js"), "GetRemoveLiquidityAmountsPoolUseCase") } // prettier-ignore
  get WithdrawBundlePoolUseCase() { return this.#getter<WithdrawBundlePoolUseCaseInput, Transaction>(async () => import("./pool/UseCases/WithdrawBundlePoolUseCase.js"), "WithdrawBundlePoolUseCase") } // prettier-ignore
  get GetHistoricalLiquidityRewardsPoolUseCase() { return this.#getter<void, GetHistoricalLiquidityRewardsPoolUseCaseOutput>(async () => import("./pool/UseCases/GetHistoricalLiquidityRewardsPoolUseCase.js"), "GetHistoricalLiquidityRewardsPoolUseCase") } // prettier-ignore

  /** User */
  get GetBalancesUserUseCase() { return this.#getter<void, UserBalances>(async () => import("./user/UseCases/GetBalancesUserUseCase.js"), "GetBalancesUserUseCase") } // prettier-ignore
  get GetAirdropRewardsUserUseCase() { return this.#getter<void, GetAirdropRewardsUserUseCaseOutput>(async () => import("./user/UseCases/GetAirdropRewardsUserUseCase.js"), "GetAirdropRewardsUserUseCase") } // prettier-ignore
  get ClaimAllocationRewardUserUseCase() { return this.#getter<ClaimAllocationRewardUserUseCaseInput, Transaction>(async () => import("./user/UseCases/ClaimAllocationRewardUserUseCase.js"), "ClaimAllocationRewardUserUseCase") } // prettier-ignore
  get ClaimMerkleRewardUserUseCase () { return this.#getter<ClaimMerkleRewardUserUseCaseInput, Transaction>(async () => import("./user/UseCases/ClaimMerkleRewardUserUseCase.js"), "ClaimMerkleRewardUserUseCase") } // prettier-ignore
  get ClaimFUSDTRewardUserUseCase() { return this.#getter<ClaimFUSDTRewardUserUseCaseInput, Transaction>(async () => import("./user/UseCases/ClaimFUSDTRewardUserUseCase.js"), "ClaimFUSDTRewardUserUseCase") } // prettier-ignore

  /** Synthetics */
  get GetDepositAmountSyntheticUseCase () { return this.#getter<GetDepositAmountSyntheticUseCaseInput, GetDepositAmountSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/GetDepositAmountSyntheticUseCase.js"), "GetDepositAmountSyntheticUseCase") } // prettier-ignore
  get DepositStakeSyntheticUseCase () { return this.#getter<DepositStakeSyntheticUseCaseInput, DepositStakeSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/DepositStakeSyntheticUseCase.js"), "DepositStakeSyntheticUseCase") } // prettier-ignore
  get GetUserSyntheticDepositsSyntheticUseCase () { return this.#getter<GetUserSyntheticDepositsSyntheticUseCaseInput, GetUserSyntheticDepositsSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/GetUserSyntheticDepositsSyntheticUseCase.js"), "GetUserSyntheticDepositsSyntheticUseCase") } // prettier-ignore
  get UpdateStakeLockTimeSyntheticUseCase () { return this.#getter<UpdateStakeLockTimeSyntheticUseCaseInput, UpdateStakeLockTimeSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/UpdateStakeLockTimeSyntheticUseCase.js"), "UpdateStakeLockTimeSyntheticUseCase") } // prettier-ignore
  get UpdateStakeAmountSyntheticUseCase () { return this.#getter<UpdateStakeAmountSyntheticUseCaseInput, UpdateStakeAmountSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/UpdateStakeAmountSyntheticUseCase.js"), "UpdateStakeAmountSyntheticUseCase") } // prettier-ignore
  get GetByIDUserSyntheticDepositSyntheticUseCase () { return this.#getter<GetByIDUserSyntheticDepositSyntheticUseCaseInput, GetByIDUserSyntheticDepositSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/GetByIDUserSyntheticDepositSyntheticUseCase.js"), "GetByIDUserSyntheticDepositSyntheticUseCase") } // prettier-ignore
  get WithdrawStakeSyntheticUseCase () { return this.#getter<WithdrawStakeSyntheticUseCaseInput, WithdrawStakeSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/WithdrawStakeSyntheticUseCase.js"), "WithdrawStakeSyntheticUseCase") } // prettier-ignore
  get GetCurrentEpochPoolStatsListSyntheticUseCase () { return this.#getter<void, EpochPoolStatsList>(async () => import("./synthetic/UseCases/GetCurrentEpochPoolStatsListSyntheticUseCase.js"), "GetCurrentEpochPoolStatsListSyntheticUseCase") } // prettier-ignore
  get GetCompletedEpochPoolStatsListSyntheticUseCase () { return this.#getter<GetCompletedEpochPoolStatsListSyntheticUseCaseInput, EpochPoolStatsList>(async () => import("./synthetic/UseCases/GetCompletedEpochPoolStatsListSyntheticUseCase.js"), "GetCompletedEpochPoolStatsListSyntheticUseCase") } // prettier-ignore
  get GetCurrentUserEpochPoolVotesListSyntheticUseCase() { return this.#getter<GetCurrentUserEpochPoolVotesListSyntheticUseCaseInput, UserEpochPoolVotesList>(async () => import("./synthetic/UseCases/GetCurrentUserEpochPoolVotesListSyntheticUseCase.js"), "GetCurrentUserEpochPoolVotesListSyntheticUseCase") } // prettier-ignore
  get GetCompletedUserEpochPoolVotesListSyntheticUseCase() { return this.#getter<GetCompletedUserEpochPoolVotesListSyntheticUseCaseInput, UserEpochPoolVotesList>(async () => import("./synthetic/UseCases/GetCompletedUserEpochPoolVotesListSyntheticUseCase.js"), "GetCompletedUserEpochPoolVotesListSyntheticUseCase") } // prettier-ignore
  get GetUserEpochRewardsSyntheticUseCase() { return this.#getter<GetUserEpochRewardsSyntheticUseCaseInput, UserEpochRewards>(async () => import("./synthetic/UseCases/GetUserEpochRewardsSyntheticUseCase.js"), "GetUserEpochRewardsSyntheticUseCase") } // prettier-ignore
  get GetCurrentEpochInfoSyntheticUseCase() { return this.#getter<void, EpochInfo>(async () => import("./synthetic/UseCases/GetCurrentEpochInfoSyntheticUseCase.js"), "GetCurrentEpochInfoSyntheticUseCase") } // prettier-ignore
  get VotePoolsSyntheticUseCase () { return this.#getter<VotePoolsSyntheticUseCaseInput, VotePoolsSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/VotePoolsSyntheticUseCase.js"), "VotePoolsSyntheticUseCase") } // prettier-ignore
  get ClaimRewardsSyntheticUseCase () { return this.#getter<ClaimRewardsSyntheticUseCaseInput, ClaimRewardsSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/ClaimRewardsSyntheticUseCase.js"), "ClaimRewardsSyntheticUseCase") } // prettier-ignore
  get GetSyntheticStatsListSyntheticUseCase () { return this.#getter<GetSyntheticStatsListSyntheticUseCaseInput, GetSyntheticStatsListSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/GetSyntheticStatsListSyntheticUseCase.js"), "GetSyntheticStatsListSyntheticUseCase") } // prettier-ignore
  get RebuildStakeSyntheticUseCase () { return this.#getter<RebuildStakeSyntheticUseCaseInput, RebuildStakeSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/RebuildStakeSyntheticUseCase.js"), "RebuildStakeSyntheticUseCase") } // prettier-ignore
  get GetHistoricalStatsSyntheticUseCase () { return this.#getter<void, GetHistoricalStatsSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/GetHistoricalStatsSyntheticUseCase.js"), "GetHistoricalStatsSyntheticUseCase") } // prettier-ignore
  get RebaseSyntheticUseCase () { return this.#getter<RebaseSyntheticUseCaseInput, RebaseSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/RebaseSyntheticUseCase.js"), "RebaseSyntheticUseCase") } // prettier-ignore
  get GetGlobalInfoSyntheticUseCase () { return this.#getter<void, GetGlobalInfoSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/GetGlobalInfoSyntheticUseCase.js"), "GetGlobalInfoSyntheticUseCase") } // prettier-ignore
  get GetHistoricalEpochRewardsSyntheticUseCase () { return this.#getter<void, GetHistoricalEpochRewardsSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/GetHistoricalEpochRewardsSyntheticUseCase.js"), "GetHistoricalEpochRewardsSyntheticUseCase") } // prettier-ignore
  get SplitSyntheticUseCase () { return this.#getter<SplitSyntheticUseCaseInput, SplitSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/SplitSyntheticUseCase.js"), "SplitSyntheticUseCase") } // prettier-ignore
  get MergeSyntheticUseCase () { return this.#getter<MergeSyntheticUseCaseInput, MergeSyntheticUseCaseOutput>(async () => import("./synthetic/UseCases/MergeSyntheticUseCase.js"), "MergeSyntheticUseCase") } // prettier-ignore

  /** Migration */
  get GetMigrationInfoUseCase () { return this.#getter<void, MigrationInfo>(async () => import("./migration/UseCases/GetMigrationInfoUseCase.js"), "GetMigrationInfoUseCase") } // prettier-ignore
  get WithdrawYieldMigrationUseCase () { return this.#getter<WithdrawYieldMigrationUseCaseInput, WithdrawYieldMigrationUseCaseOutput>(async () => import("./migration/UseCases/WithdrawYieldMigrationUseCase.js"), "WithdrawYieldMigrationUseCase") } // prettier-ignore
  get ApproveTokenMigrationUseCase () { return this.#getter<ApproveTokenMigrationUseCaseInput, ApproveTokenMigrationUseCaseOutput>(async () => import("./migration/UseCases/ApproveTokenMigrationUseCase.js"), "ApproveTokenMigrationUseCase") } // prettier-ignore
  get MigrateMigrationUseCase () { return this.#getter<MigrateMigrationUseCaseInput, MigrateMigrationUseCaseOutput>(async () => import("./migration/UseCases/MigrateMigrationUseCase.js"), "MigrateMigrationUseCase") } // prettier-ignore

  /** Admin */
  get CloseEpochAdminUseCase () { return this.#getter<void, CloseEpochAdminUseCaseOutput>(async () => import("./admin/UseCases/CloseEpochAdminUseCase.js"), "CloseEpochAdminUseCase") } // prettier-ignore
  get AddPoolIncentiveAdminUseCase () { return this.#getter<AddPoolIncentiveAdminUseCaseInput, AddPoolIncentiveAdminUseCaseOutput>(async () => import("./admin/UseCases/AddPoolIncentiveAdminUseCase.js"), "AddPoolIncentiveAdminUseCase") } // prettier-ignore
  get GetPoolIncentiveAllowanceAdminUseCase () { return this.#getter<GetPoolIncentiveAllowanceAdminUseCaseInput, GetPoolIncentiveAllowanceAdminUseCaseOutput>(async () => import("./admin/UseCases/GetPoolIncentiveAllowanceAdminUseCase.js"), "GetPoolIncentiveAllowanceAdminUseCase") } // prettier-ignore
  get ApprovePoolIncentiveAdminUseCase () { return this.#getter<ApprovePoolIncentiveAdminUseCaseInput, ApprovePoolIncentiveAdminUseCaseOutput>(async () => import("./admin/UseCases/ApprovePoolIncentiveAdminUseCase.js"), "ApprovePoolIncentiveAdminUseCase") } // prettier-ignore

  #getter<I, O>(loader: Function, name: string) {
    return {
      execute: async (input: I): Promise<O> => {
        // @ts-expect-error
        const Klass = await loader().then((mod) => mod[name])
        const uc = Klass.create({ config: this.#config })

        return uc.execute(input) as O
      },
    }
  }
}
