import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { AlertIcon, Button, CopyIcon, Modal, type ModalProps } from "@tutellus/tutellus-components"
import styles from "./index.module.css"
import { useEthereum } from "../../../context"

export type ErrorModalProps = Omit<ModalProps, "title"> & { error?: Error }

export const ErrorModal: React.FC<ErrorModalProps> = ({ children, error, width = "s", ...modalProps }) => {
  const { t } = useTranslation("common")
  const [isCopied, setIsCopied] = useState(false)
  const { wallet } = useEthereum()

  const getErrorMessage = (error: Error) => `${t("address")}: ${wallet.account}\n\n${t("error")}: ${error.message}`

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => setIsCopied(true))
      .catch((err) => console.error("Error copying: ", err))
  }

  const copyLiteral = isCopied ? t("copied") : t("copy")

  return (
    <Modal {...modalProps} title={t("error")} width={width}>
      <div className={styles.modalContent}>
        <span className={styles.modalContentIcon}>
          <AlertIcon />
        </span>
        <p className={styles.modalContentDescription}>{t("error")}</p>
        {error && (
          <div className={styles.modalContentErrorContainer}>
            <p className={styles.modalContentError}>{getErrorMessage(error)}</p>
            <button className={styles.modalContentCopyButton} onClick={() => copyToClipboard(getErrorMessage(error))}>
              {copyLiteral}
              <span className={styles.modalContentCopyButtonIcon}>
                <CopyIcon />
              </span>
            </button>
          </div>
        )}
        <div className={styles.modalContentChildrenContainer}>{children}</div>
        <a
          href="https://discord.com/channels/762592062822023179/1337428810702000231"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button isFull onClick={modalProps.onClose}>
            {t("sendToSupport")}
          </Button>
        </a>
      </div>
    </Modal>
  )
}
